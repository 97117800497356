import React, { useState } from "react"
import {
    ThemeProvider,
    theme,
    CSSReset,
    Box,
    Heading,
    Button,
    Stack,
    Flex,
    Input,
    useToast,
    Image,
    Badge,
    Icon,
    IconButton
} from "@chakra-ui/core"

function Header() {
    return (
        <Box bg="gray.700" w="100%" p={4} color="white">
            <Heading textAlign="Center">PoC Feedback App</Heading>
        </Box>
    )
}

function Card() {
    const property = {
        imageUrl: "Eventphone.svg",
        imageAlt: "Eventphone Logo",
        dect: 3861,
        sip: 1346,
        gsm: 1281,
        title: "PoC: Phone Operation Center",
        calls: " 269.807 calls",
        reviewCount: 12342,
        rating: 5
    }

    return (
        <Box maxW="sm" borderWidth="1px" rounded="lg" overflow="hidden">
            <Image src={property.imageUrl} alt={property.imageAlt} />

            <Box p="3">
                <Box d="flex" alignItems="baseline">
                    <Badge rounded="full" px="2" variantColor="teal">
                        Amazing
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        ml="2"
                    >
                        {property.dect} DECT &bull; {property.sip} SIP &bull;{" "}
                        {property.gsm} GSM
                    </Box>
                </Box>

                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    {property.title}
                </Box>

                <Box>
                    {property.calls}
                    <Box as="span" color="gray.600" fontSize="sm"></Box>
                </Box>

                <Box d="flex" mt="2" alignItems="center">
                    {Array(5)
                        .fill("")
                        .map((_, i) => (
                            <Icon
                                name="star"
                                key={i}
                                color={
                                    i < property.rating
                                        ? "yellow.300"
                                        : "gray.300"
                                }
                            />
                        ))}
                    <Box as="span" ml="2" color="gray.600" fontSize="sm">
                        {property.reviewCount} reviews
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function Composer() {
    const toast = useToast()
    const [input, setInput] = useState("")
    const [rating, setRating] = useState(-1)
    return (
        <Flex justify="center">
            <Flex maxW="sm" wrap="wrap" justify="center" direction="row" p={1}>
                <Box p={3}>
                    {Array(5)
                        .fill("")
                        .map((_, i) => (
                            <Icon
                                onClick={() => {
                                    setRating(i)
                                }}
                                name="star"
                                key={i}
                                color={i <= rating ? "yellow.300" : "gray.300"}
                            />
                        ))}
                </Box>
                <Input
                    maxW="sm"
                    placeholder="Enter your feedback."
                    value={input}
                    onChange={event => setInput(event.target.value)}
                />

                <Button
                    m={"5"}
                    onClick={() => {
                        setRating(-1)
                        setInput("")
                        toast({
                            title: "Feedback deleted.",
                            description: "We're only sending.",
                            status: "success",
                            duration: 9000,
                            isClosable: true
                        })
                    }}
                >
                    Send feedback
                </Button>
            </Flex>
        </Flex>
    )
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CSSReset />
            <Header />
            <Stack spacing={8} align="center" margin={8}>
                <Card />
            </Stack>
            <Composer />
        </ThemeProvider>
    )
}

export default App
